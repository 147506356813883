export default function Carousel() {
    return {
        id: null, 
        image: null, 
        imgUrl: null, 
        judul: null, 
        labels: [],
        nama: null, 
        status: null,
        subJudul: null, 
        tanggalAkhir: null,
        tanggalMulai: null
    };
}
