<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.carousel') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <input
              type="hidden"
              class="form-control"
              v-model.number="carousel.id"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('module.carousel') }}:</label>
              <div class="col-lg-6">
                <div
                  class="dropzone"
                  :action="urlUpload"
                  id="kt-dropzone-one"
                >
                  <div class="dropzone-msg dz-message needsclick">
                    <h1><i
                        class="fa fa-upload"
                        aria-hidden="true"
                      ></i></h1>
                    <h3 class="dropzone-msg-title">Upload</h3>
                    <span class="dropzone-msg-desc">Tarik gambar ke sini atau klik untuk upload.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.name') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="carousel.nama"
                  v-validate="'required'"
                  data-vv-as="Nama"
                  name="nama"
                  :class="{'is-invalid': errors.has('nama') }"
                />
                <div
                  v-show="errors.first('nama')"
                  class="invalid-feedback"
                >{{ errors.first('nama') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.judul') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="carousel.judul"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.subJudul') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="carousel.subJudul"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.tanggalMulai') }}:</label>
              <div class="col-lg-6">
                <date-picker
                  :changedValue.sync="carousel.tanggalMulai"
                  :id-date-picker="'carousel_start_' + carousel.id"
                  :picker-value="carousel.tanggalMulai"
                  place-holder="DD/MM/YYYY"
                  :is-validate="true"
                  start-date=""
                  end-date=""
                ></date-picker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.tanggalSelesai') }}:</label>
              <div class="col-lg-6">
                <date-picker
                  :changedValue.sync="carousel.tanggalAkhir"
                  :id-date-picker="'carousel_end_' + carousel.id"
                  :picker-value="carousel.tanggalAkhir"
                  place-holder="DD/MM/YYYY"
                  :is-validate="true"
                  start-date=""
                  end-date=""
                ></date-picker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.label') }}:</label>
              <div class="col-lg-6">
                <label-area
                  v-model="carousel.labels"
                  :multiple="true"
                ></label-area>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.status') }}:</label>
              <div class="col-lg-6">
                <div class="kt-radio-inline">
                  <label class="kt-radio">
                    <input
                      type="radio"
                      name="status"
                      v-model="carousel.status"
                      value="PUBLISH"
                    > Publish
                    <span></span>
                  </label>
                  <label class="kt-radio">
                    <input
                      type="radio"
                      name="status"
                      v-model="carousel.status"
                      value="DRAFT"
                    > Draft
                    <span></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >{{ $t('button.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "../../_general/DatePicker";
import LabelArea from "../../_select/LabelArea";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import Carousel from "../../../model/carousel-model";
import Photo from "../../../model/photo-model";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const CarouselRepository = RepositoryFactory.get("carousel");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  components: {
    DatePicker,
    LabelArea
  },
  data() {
    return {
      carousel: new Carousel(),
      photo: new Photo(),
      urlImage:  process.env.VUE_APP_API_URL + "/gallery/api/image/alt/",
      urlUpload: process.env.VUE_APP_API_URL + "/gallery/api/photos",
    };
  },
  props: {
    modalName: { type: String },
    modalData: {}
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    updateData: function (data) {
      this.carousel = data;
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {

          if (this.carousel.id) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data Carousel akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(vx.carousel);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data Gambar akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.create(vx.carousel);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          }
          return;
        }
      });
    },
    async update(payload) {
      var vx = this;

      await CarouselRepository.updateCarousel(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data Carousel berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data Carousel gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;

      await CarouselRepository.createCarousel(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Carousel berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data Carousel gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    var vx = this;

    $("#" + this.modalName).modal();

    var myDropzone = new Dropzone("#kt-dropzone-one", {
      paramName: "file", // The name that will be used to transfer the file
      maxFiles: 1,
      maxFilesize: 5, // MB
      addRemoveLinks: true,
      autoProcessQueue: true,
      withCredentials: true,
      createImageThumbnails: true,
      headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: "Bearer " + localStorage.tokens,
          "cache-control": "no-cache",
          "Content-Type": "application/json;charset=UTF-8"
        },
      accept: function(file, done) {
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function(event) {
              var image = new Image();
              image.src = event.target.result;
              image.onload = function(i) {
                vx.photo.image = event.target.result.substring(
                  event.target.result.indexOf(",") + 1
                );
                vx.photo.title = file.name;
                vx.photo.description = file.name;
                vx.photo.imageContentType = file.type;
                vx.photo.height = this.height;
                vx.photo.width = this.width;
                vx.photo.taken = moment(file.lastModified);
                vx.photo.uploaded = moment();
                vx.photo.uuid = file.upload.uuid;
                resolve(true);
              };
            };
          }).then(res => {
            done();
          });
        },
      init: function () {
        this.on("success", function(file, response) {
            vx.carousel.imgUrl = response.alias
            $(".dz-hidden-input").prop("disabled",true);
        });
        this.on("error", function(file, message) {
            $(file.previewElement)
                .addClass("dz-error")
                .find(".dz-error-message")
                .text(
                "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
            );
        });
        this.on("sending", function(file, xhr, formData) {
            const x = JSON.stringify(vx.photo);
            xhr.send(x);
        });
        this.on("thumbnail", function (file) {
            // var replacedDataURL = file.dataURL.substring(file.dataURL.indexOf(",") + 1);
            // vx.carousel.image = replacedDataURL;
        });
        this.on("removedfile", function (file) {
            vx.carousel.imgUrl = null;
            $(".dz-hidden-input").prop("disabled",false);
        });
      }
    });

    if (this.modalData) {
      this.updateData(this.modalData);

      if (this.modalData.imgUrl) {
        var mockFile = { name: "mock.jpg", size: 12345 };
        myDropzone.options.addedfile.call(myDropzone, mockFile);
        // myDropzone.options.thumbnail.call(myDropzone, mockFile, "data:" + this.modalData.imageContentType + ";base64," + this.modalData.image);
        myDropzone.options.thumbnail.call(
            myDropzone,
            mockFile,
            vx.urlImage + vx.modalData.imgUrl
        );
        $(".dz-hidden-input").prop("disabled",true);
      }
    }
  }
};
</script>
